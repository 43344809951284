import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Input } from "@virtualcapital/utogi-ui-library";

import Header from "components/header";
import TextArea from "containers/TextArea";

import "./contactUs.scss";

const ContactUs = () => {
  const SUBMIT_MESSAGE = gql`
    mutation(
      $name: String!
      $email: String!
      $message: String!
      $companyName: String!
      $contactNumber: String!
    ) {
      miscellaneous {
        contact(
          email: $email
          name: $name
          message: $message
          companyName: $companyName
          contactNumber: $contactNumber
        ) {
          success
        }
      }
    }
  `;

  const [name, changeName] = useState("");
  const [email, changeEmail] = useState("");
  const [message, changeMessage] = useState("");
  const [companyName, changeCompanyName] = useState("");
  const [contactNumber, changePhoneNumber] = useState("");

  const [isSuccessful, setIsSuccessful] = useState(false);
  const [errors, setErrors] = useState({
    name: null,
    email: null,
    message: null,
    companyName: null,
    contactNumber: null,
  });

  const [submitMessage] = useMutation(SUBMIT_MESSAGE, {
    update(_cache: any, { data }: any) {
      if (
        data &&
        data.miscellaneous &&
        data.miscellaneous.contact &&
        data.miscellaneous.contact.success
      ) {
        setIsSuccessful(true);
        changeName("");
        changeEmail("");
        changeMessage("");
        changeCompanyName("");
        changePhoneNumber("");
      }
    },
  });

  const onFormSubmit = () => {
    let errors: any = {
      name: null,
      email: null,
      message: null,
      companyName: null,
      contactNumber: null,
    };
    let hasErrors = false;

    if (name.trim() === "") {
      errors.name = "Name is required.";
      hasErrors = true;
    }

    if (email.trim() === "") {
      errors.email = "Email is required.";
      hasErrors = true;
    }

    if (message.trim() === "") {
      errors.message = "Message is required.";
      hasErrors = true;
    }

    if (email.trim() === "") {
      errors.companyName = "Company Name is required.";
      hasErrors = true;
    }

    if (message.trim() === "") {
      errors.contactNumber = "Contact Number is required.";
      hasErrors = true;
    }

    setErrors(errors);

    if (!hasErrors) {
      submitMessage({
        variables: {
          name,
          email,
          message,
          companyName,
          contactNumber,
        },
      });
    }
  };

  return (
    <div className="page contact-us">
      <header>
        <Header />
        <div className="header-block container">
          <span className="heading">Contact Us</span>
        </div>
      </header>
      <section className="utogi-feature-section background-image">
        <div className="container">
          <div className="contact-form">
            <Input
              placeholder="Full Name"
              error={errors.name}
              onChange={changeName}
            />
            <Input
              placeholder="Email"
              error={errors.email}
              onChange={changeEmail}
            />
            <Input
              placeholder="Company Name"
              error={errors.companyName}
              onChange={changeCompanyName}
            />
            <Input
              placeholder="Contact Number"
              error={errors.contactNumber}
              onChange={changePhoneNumber}
            />
            <TextArea
              placeholder="Message"
              error={errors.message}
              onChange={changeMessage}
            />
            {isSuccessful && (
              <div className="alert alert-success">
                Thank you! Your message has been sent.
              </div>
            )}
            <button onClick={onFormSubmit}>Submit</button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
