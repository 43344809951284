import React from "react";
import classNames from "classnames";

import "./TextArea.scss";

type TextAreaProps = {
  placeholder: string;
  onChange: Function;
  error: null | string;
  className?: string;
  disabled?: boolean;
};

const TextArea = ({
  placeholder,
  onChange = () => {},
  error,
  className,
  disabled = false,
}: TextAreaProps) => {
  const hasErrors = error !== null;
  const getFieldErrors = (error: string | null) =>
    error !== null && <div className="form-errors"> {error} </div>;
  return (
    <div
      className={classNames(
        "form-input",
        {
          "has-errors": hasErrors,
        },
        className
      )}
    >
      <textarea
        placeholder={placeholder}
        onChange={({ target: { value } }) => onChange(value)}
        disabled={disabled}
        rows={5}
      ></textarea>
      {getFieldErrors(error)}
    </div>
  );
};

export default TextArea;
