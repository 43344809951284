import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Loader from "components/loader";

import HomePage from "pages/home";
import ContactUs from "pages/contactUs";
import PrivacyPolicy from "pages/privacyPolicy";
import TermsAndConditions from "pages/termsAndConditions"

import GraphQLProvider from "graphql/client";

import "./App.scss";

function App() {
  return (
    <GraphQLProvider>
      <Router>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route exact path="/contact-us">
              <ContactUs />
            </Route>
            <Route exact path="/privacy-policy">
              <PrivacyPolicy/>
            </Route>
            <Route exact path="/terms-and-conditions">
              <TermsAndConditions/>
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </GraphQLProvider>
  );
}

export default App;
