import { ApolloProvider } from "@apollo/react-hooks";
import ApolloClient from "apollo-boost";
import config from "config";
import React from "react";

const client = () =>
  new ApolloClient({
    uri: `${config.DOMAIN_PROTOCOL}api.${config.DOMAIN_SUFFIX}/graphql`,
  });

client.defaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
  mutate: {
    errorPolicy: "all",
  },
};

export default ({ children }: { children: React.ReactNode }) => (
  <ApolloProvider client={client()}>{children}</ApolloProvider>
);
