import React from "react";
import { Link } from "react-router-dom";

import "./header.scss";
import config from "config";

const header = () => {
  return (
    <div className="navigation-menu">
      <div className="navigation-menu-left">
        <Link to="/">
          <div className="logo">
            <img src={require("../../assets/images/logo.png")} alt="Utogi" />
          </div>
        </Link>
      </div>
      <nav>
        <ul>
          <li className="login">
            <a href={`${config.DOMAIN_PROTOCOL}auth.${config.DOMAIN_SUFFIX}`}>
              Login
            </a>
          </li>
          <li>
            <Link className="cta-button" to="/contact-us">
              Contact Us
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default header;
