import React from "react";

import "./loader.scss";

const loader = () => {
  return (
    <div className="loader">
      <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default loader;
