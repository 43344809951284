import React from "react";

import Header from "components/header";

import "./privacyPolicy.scss";

const privacyPolicy = () => {
  return (
    <div className="page privacy-policy">
      <header>
        <Header />
        <div className="header-block container">
          <span className="heading">Privacy Policy</span>
        </div>
      </header>
      <section className="container background-image">
        <p>
          Utogi Limited (<strong>we, us</strong> or <strong>our</strong>) is committed to protecting and maintaining the
          privacy, accuracy and security of your personal information.
        </p>
        <p>
          This privacy policy sets out details of how we collect, use, store and disclose your personal information. By
          accessing and using our services and website, you consent to the collection, use, storage and disclosure of
          your personal information in accordance with this policy.
        </p>
        <h3>HOW WE COLLECT YOUR INFORMATION</h3>
        <p>
          We will only collect personal information relevant to our business relationship with you. The personal
          information we collect will generally include your name, address, telephone numbers, email address, credit
          card or bank account details, and date of birth. You may choose to not provide us with this information, but
          not doing so may affect our ability to provide you with our services.
        </p>
        <p>We will collect this information directly from you when you:</p>
        <ul>
          <li>register to use our services;</li>
          <li>use our services; or</li>
          <li>communicate or interact with us, whether by email, telephone or otherwise.</li>
        </ul>
        <p>
          You are required to keep us informed of changes to your information to enable us to have proper administrative
          processes.
        </p>
        <p>
          We may also collect aggregated information generated by our system, which tracks your use of our services but
          does not identify you personally.
        </p>
        <h3>COOKIES</h3>
        <p>
          Our website uses server logs and web analytic tools (such as &ldquo;cookies&rdquo;). Cookies are small text
          files that are downloaded to your device by websites that you visit. When you use our website, these tools
          collect information such as the browser and operating system that you use, the internet protocol address of
          the device you use to access the site, search terms, your location and the content that you view when visiting
          the website.
        </p>
        <p>
          You can set your browser to block all cookies, including cookies associated with our website, or to indicate
          when a cookie is being set by us. If you set your browser to reject cookies you may not be able to access all
          the features of the website.
        </p>
        <h3>HOW WE USE YOUR INFORMATION</h3>
        <p>We may use your personal information to:</p>
        <ul>
          <li>identify you, so we can ensure that your account is secure;</li>
          <li>provide our services to you;</li>
          <li>communicate with you;</li>
          <li>
            carry out our business which includes maintaining your account, planning, training, product development,
            research and analysis;
          </li>
          <li>enforce our agreement with you in any way;</li>
          <li>
            fulfil our legal requirements (for example, disclosure to law enforcement agencies or the courts); and
          </li>
          <li>or for any related purpose in connection with the above.</li>
        </ul>
        <p>
          We may use any information that we collect from you that is not personal information (so you cannot be
          personally identified from it) for our business purposes, including:
        </p>
        <ul>
          <li>to assess how our customers use our services;</li>
          <li>to improve our services; and</li>
          <li>for marketing and promotional purposes.</li>
        </ul>
        <h3>OPT OUT</h3>
        <p>
          We may use your information to offer you services that we believe meet your needs. However, you can notify us
          at any time if you do not wish to receive these offers by emailing us at{" "}
          <a href="mailto:info@utogi.com">info@utogi.com</a>. We will act promptly on any such request.
        </p>
        <h3>WHO WILL USE YOUR INFORMATION?</h3>
        <p>
          We generally do not share your personal information with others unless this is necessary for the purpose for
          which you gave us the information. While it is unlikely, we may be required to disclose your information to
          comply with legal or regulatory requirements. We will use reasonable endeavours to notify you before we do
          this, unless we are legally restricted from doing so.
        </p>
        <p>
          We may disclose information we retain about you, including your personal information, to the following third
          parties or their agents:
        </p>
        <ul>
          <li>
            service providers engaged by us, such as data storage, Information technology and software management
            providers;
          </li>
          <li>our related entities;</li>
          <li>government departments or law enforcement agencies (including the Police);</li>
          <li>
            any person than can demonstrate to our reasonable satisfaction has a legal or equitable interest in the
            goods stored;
          </li>
          <li>liquidators, administrators or other persons appointed to administer your financial affairs; and</li>
          <li>debt collection services or credit reporting agencies.</li>
        </ul>
        <p>
          Some of the parties listed above may be located overseas. Where your personal information is transferred
          outside New Zealand, we will ensure that the information will be protected to the same standard as it is by us
          in New Zealand, and that your rights in relation to the personal information remain available, or we will
          obtain your express consent to transfer or store the personal information.
        </p>
        <p>
          We may disclose, sell or transfer to third parties any non-personal, aggregated information that we collect
          from you and our other customers.
        </p>
        <h3>YOUR INFORMATION IS SECURE</h3>
        <p>
          We will ensure your personal information is kept secure and confidential, using up to date techniques which
          meet current industry standards. This includes the following:
        </p>
        <ul>
          <li>
            Only our staff and those who perform services on our behalf, and are authorised to handle your information,
            will have access to your personal information.
          </li>
          <li>
            We will not retain any of your information for any longer than it is required by us except to fulfil our
            legal obligations or where you have consented.
          </li>
          <li>We will, with your help, keep your personal information accurate, complete and up-to-date.</li>
        </ul>
        <h3>ADVERTISING AND THIRD PARTY LINKS</h3>
        <p>
          Our website may contain links to a variety of advertising and third party website sources. Some of these links
          may request or record information from users or use cookies or other methods to collect information from you.
          We have no control over the content or privacy policy practices of those sites and encourage you to review the
          privacy policies of those sites before using them.
        </p>
        <h3>YOU HAVE ACCESS TO YOUR INFORMATION</h3>
        <p>
          You can access most of the personal information we hold about you and request corrections. This right is
          subject to some exceptions, for example, you may not obtain access to information relating to existing or
          anticipated legal proceedings.
        </p>
        <p>
          You can request access to your information by emailing us at{" "}
          <a href="mailto:info@utogi.com">info@utogi.com</a>. This service is free unless the information you request
          requires significant research or preparation time. Before we act upon requests of this nature, we will tell
          you how much this service will cost.
        </p>
        <h3>WHAT TO DO IF YOU THINK WE HAVE MADE AN ERROR</h3>
        <p>
          We are committed to protecting your privacy and our policies, processes and systems have been developed with
          this in mind. However, if you think we have made an error, please email us to let us know. Where we have made
          an error, we will endeavour to correct the error as soon as reasonably practicable.
        </p>
        <h3>DELETING YOUR DATA</h3>
        <p>
          You have the right to request deletion of your Personal Data when there is no good reason for Us to continue processing it. 
          <br />
          You can request deletion by emailing <a href="mailto:support@utogi.com">support@utogi.com</a>
        </p>
        <p>
          We will delete your personal information from our records, unless an exception applies.  We may deny your deletion request if retaining information is necessary for Us or Our Service Providers to:
        </p>
        <ul>
          <li>Complete transactions for which We collected the personal information, provide a good or service that that You requested, take actions anticipated within the context of our ongoing business relationship with You, or otherwise perform our contract with You.</li>
          <li>Detect security incidents, protect against malicious, deceptive, fraudulent or illegal activity, or prosecute those responsible for such activities</li>
          <li>Debug products to identify and repair errors that impair existing intended functionality</li>
          <li>Comply with a legal obligation</li>
          <li>Make other internal and lawful uses of that information that are compatible with the context in which You provided it.</li>
        </ul>
        <h3>QUESTIONS AND COMPLAINTS</h3>
        <p>
          If you have a question or complaint about the way we have dealt with your personal information, please contact
          us by email. We will endeavour to respond promptly to your question or complaint.
        </p>
        <h3>breaches of your privacy</h3>
        <p>
          We take our privacy responsibilities seriously. In the unlikely event that a suspected or actual breach of
          your personal data occurs, we will investigate the breach. We will notify you and the Privacy Commissioner if
          we reasonably believe that the breach has caused you serious harm, or is likely to cause you serious harm..{" "}
        </p>
        <h3>CHANGES TO POLICY</h3>
        <p>
          We may change this policy at any time by publishing the amended policy on our website. We will endeavour to
          inform you of any changes to the policy by email or on the website. If you have any questions about our
          privacy policy or any changes to it, you can contact us by email at{" "}
          <a href="mailto:info@utogi.com">info@utogi.com</a>..{" "}
        </p>
      </section>
    </div>
  );
};

export default privacyPolicy;
