import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@virtualcapital/utogi-ui-library";

import Header from "components/header";

import "./home.scss";

const home = () => {
  return (
    <div className="home-page">
      <header>
        <Header />
        <div className="container slider-content">
          <div className="slider-left">
            <span className="heading">
              Ignite your taste for growth with
              <br />
              Utogi's brand saturation tools
            </span>
            <span className="sub-heading">
              A fresh fusion of people & property data
            </span>
            <div className="coming-soon">
              <span>-Coming Soon-</span>
            </div>
            <Link to="/contact-us" className="cta">
              Contact us
            </Link>
          </div>
          <div className="slider-right">
            <div className="animated-icon">
              <Icon icon="envelop" />
            </div>
            <div className="animated-icon">
              <Icon icon="people" />
            </div>
            <div className="animated-icon">
              <Icon icon="marketplace" />
            </div>
            <div className="animated-icon">
              <Icon icon="marketing" />
            </div>
            <div className="animated-icon">
              <Icon icon="compliance" />
            </div>
            <img
              src={require("assets/images/home/slider-right.png")}
              alt="slider right"
            />
          </div>
        </div>
      </header>
      <footer>
        <ul>
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/terms-and-conditions">Terms and Conditions</Link>
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default home;
