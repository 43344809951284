import React from "react";
import { Link } from "react-router-dom";

import Header from "components/header";

import "./termsAndConditions.scss";

const termsAndConditions = () => {
  return (
    <div className="page terms-and-conditions">
      <header>
        <Header />
        <div className="header-block container">
          <span className="heading">Terms and Conditions</span>
        </div>
      </header>
      <section className="container background-image">
        <ol className="terms-and-conditions-list">
          <li>
            <h3>application of terms</h3>
          </li>
          <ol>
            <li>
              Utogi Limited (<strong>we</strong>, <strong>us</strong> or &nbsp;
              <strong>our</strong>) owns and operates an online platform (<strong>platform</strong>) where users who
              have registered on the platform (<strong>you</strong> or <strong>your</strong>)may access our real estate
              agency management services (<strong>services</strong>).
            </li>
            <li>
              These terms and conditions (<strong>terms</strong>) apply to your use of our services. Please carefully
              read these terms before you use our services.
            </li>
            <li>
              By registering for and accessing our services, you agree that you have read, understood and accepted these
              terms and agree to be bound by them.
            </li>
            <li>
              If you access these terms on behalf of a real estate agency (<strong>agency</strong>) or another person,
              you confirm that you are authorised by that agency or person to accept these terms on that agency’s or
              person’s behalf, and that agency or person is bound by these terms.
            </li>
            <li>If you do not agree to these terms, you must cease to access and use the services immediately.</li>
            <li>
              We may change these terms at any time by notifying you of the change by email or on our platform. Any
              change to these terms will take effect from the date set out in the notice. By continuing to access and
              use our services, you agree to be bound by the amended terms.
            </li>
          </ol>
          <li>
            <h3>OUR SERVICES</h3>
          </li>
          <ol>
            <li>
              Our platform is provided via our website at [www.utogi.com] [insert link] and/or our mobile app, and which
              allows users to access and use various real estate agency management services corresponding to the modules
              to which they subscribe.
            </li>
            <li>
              In providing our services to you, we will act with due care and skill and in accordance with applicable
              laws.
            </li>
            <li>
              These terms will apply (to the extent applicable) in relation to any trial that we may offer from time to
              time in respect of your use of our services. If you sign up for a trial, and subject to any terms
              expressly notified to you in relation to the trial, you will have no obligation to continue to subscribe
              for our services at the end of the trial period.
            </li>
            <li>
              If you choose to continue using our services after the expiry of the trial period, you will be charged for
              using our services from the date immediately following the expiry of the trial period. If you choose not
              to continue using our services after the expiry of the trial period, then your subscription will terminate
              upon the expiry of the trial period.
            </li>
            <li>
              We will endeavour to make our platform and services available 24 hours a day, 7 days a week, except for:
              <ol type="a">
                <li>scheduled maintenance carried out during our maintenance window of 9pm to 4am; and</li>
                <li>
                  unscheduled maintenance performed outside of normal business hours. We will use reasonable endeavours
                  to give you advance notice of any unscheduled maintenance.
                </li>
              </ol>
            </li>
            <li>
              We will endeavour to provide support for the services through our self-help module on the platform and our
              ticketing system. We may also provide telephone support from time to time. If any fees apply to any means
              of support, we will notify you in advance of these fees before you incur them.
            </li>
          </ol>
          <li>
            <h3>YOUR OBLIGATIONS AND RESTRICTIONS</h3>
          </li>
          <ol>
            <li>In order to become a registered user of our service, you must:</li>
            <ol type="a">
              <li>be at least 18 years old and able to enter into contracts;</li>
              <li>
                have provided us with complete, accurate and up-to-date information when registering for our services;
              </li>
              <li>only register once (unless we agree in writing otherwise); and</li>
              <li>complete our registration process.</li>
            </ol>
            <li>
              If you register for our services on behalf of an agency, company or other business, you must be authorised
              by it to register on its behalf.
            </li>
            <li>
              You must keep your user name and password details secure and will ensure that only you (and nobody else)
              accesses and uses our platform and services. You are responsible for all activity on your account so if
              you think that some else is accessing your account then please contact us immediately at{" "}
              <a href="mailto:info@utogi.com">info@utogi.com</a>.
            </li>
            <li>
              You must keep your account details up to date. If your details change, you should update your account with
              the new details as soon as possible.
            </li>
            <li>
              If you message or share information with any of our other users, you must be respectful and polite to
              those other users and must not at any time behave in a threatening, abusive or offensive way to those
              other users.
            </li>
            <li>You must:</li>
            <ol type="a">
              <li>
                not use our platform and services for any illegal or harmful purposes or in a way that could adversely
                affect our reputation or goodwill;
              </li>
              <li>not knowingly introduce viruses or other harmful code onto our platform;</li>
              <li>not permit any person to link to our platform without our prior written consent;</li>
              <li>
                not use our services in a way that could adversely affect the performance of the platform or the
                services we provide;
              </li>
              <li>
                not use our platform and services to send unsolicited communications or other spam to any other person;
              </li>
              <li>only use the platform and our services in accordance with these terms; and</li>
              <li>comply with applicable laws in your use of the platform and services.</li>
            </ol>
            <li>To the maximum extent permitted by law, you must not:</li>
            <ol type="a">
              <li>
                copy, modify or adapt our platform or services, or create any derivative works based upon the platform
                or services;
              </li>
              <li>disrupt or interfere with our platform or services;</li>
              <li>decompile, disassemble or otherwise reverse engineer the platform or any part of it; or</li>
              <li>
                modify or remove our (or any other) copyright or proprietary notices on the platform or any part of it,
              </li>
            </ol>
            <li>
              You must not attempt to perform any of the acts described in clauses 3.5 to 3.7 above, or permit or
              encourage any other person to do so.
            </li>
            <li>
              Please note that, in accordance with our privacy policy [insert link], we can disclose your personal
              information and other relevant information such as transaction records and content to any relevant
              authorities or other relevant persons.
            </li>
          </ol>
          <li>
            <h3>FInANCIAL</h3>
          </li>
          <ol>
            <li>In these terms:</li>
            <ol type="a">
              <li>
                <strong>fees </strong>
                means the fees payable by you for our services (as notified to you when you register for our services or
                as otherwise payable in accordance with these terms);
              </li>
              <li>
                <strong>non-subscription fees</strong>
                means any fees or charges (other than subscription fees in relation to a plan) payable by you in
                relation to the supply of the services;
              </li>
              <li>
                <strong>plan</strong>
                means the plan (including any modules) to which you subscribe at the time that payment is due; and
              </li>
              <li>
                <strong>subscription fees</strong>
                means the fees payable by you for subscribing to a plan.
              </li>
            </ol>
            <li>
              You will pay to us the fees in accordance with these terms. Unless otherwise agreed by us in writing, all
              amounts payable under these terms are payable in New Zealand dollars. The fees exclude any goods and
              services tax or other sales tax, which you will pay on taxable supplies under these terms.
            </li>
            <li>
              We offer various payment options in relation to our fees. The options are currently payment by credit or
              debit card, or by electronic transfer following receipt of our invoice. The payment options that are
              available to you will depend on which type of user that you are and the service you are using.
            </li>
            <li>
              If you pay any fees by credit card, a surcharge will apply as notified to you at the time of payment.
            </li>
            <li>
              If we invoice you for payment, you must pay the invoice by electronic transfer to our nominated bank
              account on or before the date which is 14 days following the date of the invoice.
            </li>
            <li>
              If you have not paid an invoice in full by the due date for payment, we may charge interest on any unpaid
              amount from that date until the date of payment, to be calculated on a daily basis at a rate equal to the
              corporate overdraft reference rate of our principal bank at the payment date, plus 3% per annum.
            </li>
            <li>We may increase the subscription fees at any time:</li>
            <ol type="a">
              <li>
                for annual subscriptions, by giving to you not less than one months’ written notice of the increase
                prior to the expiry of the then current annual subscription; and
              </li>
              <li>
                for monthly subscriptions, by giving to you not less than one months’ prior written notice of the
                increase.
              </li>
            </ol>
            <li>We will notify you of any increase to the subscription fees by email or on our platform.</li>
            <li>
              Unless we agree otherwise, you will pay the subscription fees monthly in advance without set off or
              deduction and by a direct transfer to our nominated bank account.
            </li>
            <li>
              If a commission is payable to us in respect of any transaction that is carried out on the platform, we
              will retain the commission and pay you the amount owing to you after the deduction of the commission.
            </li>
            <li>
              We use a third party payment processor to process certain payments, who may charge a fee for providing
              this service. Such a fee will be notified to you before your transaction is processed.
            </li>
            <li>
              We may provide a refund to you for any services at our sole discretion. If we decide to offer you a
              refund, we will deduct any third party payment processing fees from any refund before providing the refund
              to you.
            </li>
            <li>
              If you do not pay any amount due under these terms within 30 days of the due date, and provided that we
              have sent a reminder notice to you detailing the overdue payment, we may suspend your access to our
              services on written notice to you. Alternatively we may terminate your subscription to our services in
              accordance with clause 11 below.
            </li>
          </ol>
          <li>
            <h3>YOUR Data</h3>
          </li>
          <ol>
            <li>In this clause 5:</li>
            <ol type="a">
              <li>
                <strong>aggregated data</strong>
                means anonymised and aggregated statistical and analytical data generated by us from our use of the
                services, which tracks your use of the services but does not contain any of your personal information
                (including in respect of your customers, staff, agents or representatives);
              </li>
              <li>
                <strong>personal information</strong>
                has the meaning given to that term in the Privacy Act 1993 (NZ) as amended, extended or re-enacted from
                time to time; and
              </li>
              <li>
                <strong>your data </strong>
                means the data, information and content belonging to you (including any personal information) that is
                accessed by, or provided to, us under or in connection with these terms.
              </li>
            </ol>
            <li>We will:</li>
            <ol type="a">
              <li>
                only process any personal information in accordance with the terms of our{" "}
                <Link to="/privacy-policy">privacy policy</Link>;
              </li>
              <li>
                only process any personal information for the purposes of performing our obligations and exercising our
                rights under these terms; and
              </li>
              <li>
                put in place appropriate security measures (both technical and organisational) against the unlawful or
                unauthorised processing of personal information and against the loss or corruption of personal
                information;
              </li>
              <li>only process any personal information in compliance with applicable laws.</li>
            </ol>
            <li>You will:</li>
            <ol type="a">
              <li>
                obtain all authorisations, approvals and consents required for us to access and use your data in
                accordance with these terms (<strong>authorisations</strong>), including any authorisations required
                from individuals to enable us to collect, hold, use and process any personal information under these
                terms; and
              </li>
              <li>
                provide us with such access to your data as is required to enable us to perform our obligations under
                these terms.
              </li>
            </ol>
            <li>You acknowledge and agree that we:</li>
            <ol type="a">
              <li>
                may use your data, and any related information regarding the use of the platform and services, to
                generate aggregated data;
              </li>
              <li>will own all intellectual property rights in and to the aggregated data; and</li>
              <li>
                may use, disclose, sell, transfer, or otherwise deal with the aggregated data at our sole discretion.
              </li>
            </ol>
            <li>
              To the maximum extent applicable by law, if you are an agency or other business customer you will
              indemnify us against any loss, damages, costs or expenses arising from any claim or alleged claim by a
              third party that your data infringes the rights (including the intellectual property rights) of that third
              party, or arising from any failure by you to obtain any authorisations.
            </li>
            <li>
              You acknowledge and agree that we may transfer and store your data (including any personal information)
              to, from and in secure servers outside of New Zealand, and may access your data (including any personal
              information) within and outside of New Zealand from time to time.
            </li>
          </ol>
          <li>
            <h3>YOUR CONTENT</h3>
          </li>
          <ol>
            <li>
              You confirm that, if you publish any content and information (including words and images) on our platform,
              including any content relating to properties such as property descriptions, photographs and videos or
              content relating to any other person (<strong>content</strong>):
            </li>
            <ol type="a">
              <li>you have the right to publish the content;</li>
              <li>to the best of your knowledge, the content is accurate, complete or up-to-date;</li>
              <li>
                the publication of the content will not infringe the intellectual property rights or other proprietary
                rights of any other person; and
              </li>
              <li>the publication of the content will not breach any of these terms.</li>
            </ol>
            <li>You acknowledge and agree that:</li>
            <ol type="a">
              <li>
                we are not responsible for reviewing any content (including property descriptions, photographs and
                videos) posted by other users including agencies; and
              </li>
              <li>
                we do not warrant or guarantee that any content posted by other users is accurate, complete or
                up-to-date.
              </li>
            </ol>
            <li>
              We can delete or reject any content, immediately and without giving you notice, if we determine that the
              content does not comply with these terms.
            </li>
            <li>
              You, or the person who has given you permission to use the content, owns the content. So, to be clear, we
              do not claim ownership of the content or any part of it.
            </li>
            <li>
              You must keep copies of all your data. While we have policies and procedures in place to prevent or
              minimise any data loss from our platform, we do not warrant or guarantee that there will be no loss of
              your data from the use of our platform and our services. We exclude all liability for any loss of your
              data however this is caused.
            </li>
            <li>
              We will use any personal information that you provide to us in any content in accordance with the terms of
              our privacy policy. [insert link]
            </li>
          </ol>
          <li>
            <h3>Intellectual Property Rights</h3>
          </li>
          <ol>
            <li>
              We or our licensors own all intellectual property rights in relation to our platform and services, and in
              any improvements, modifications, additions and enhancements to our platform and services.
            </li>
            <li>
              We grant to you a non-exclusive, non-transferable, revocable licence to use our platform and services
              during the period in which you have a subscription in accordance with these terms.
            </li>
            <li>
              Nothing in these terms limits our right to use any ideas, concepts, methodologies, processes and know-how
              that are used, developed or created in the course of providing the platform and services to you.
            </li>
            <li>
              You grant to us a worldwide, non-exclusive, royalty free, transferable, irrevocable and perpetual licence
              to publish, display, use, modify and sub-license your content, and to make it available to any person in
              any form, in order to provide our platform and services and for our marketing purposes.
            </li>
            <li>
              You will not do anything to invalidate or adversely affect our intellectual property rights in and to our
              platform and services.
            </li>
          </ol>
          <li>
            <h3>MODULES</h3>
          </li>
          <ol>
            <li>We may at our sole discretion provide to users:</li>
            <ol type="a">
              <li>
                a “market place” module (or similar) where users who are service providers can offer their services for
                sale to other users;
              </li>
              <li>a “shop” module (or similar) where users can purchase branded promotional products.</li>
            </ol>
            <li>
              If we offer either or both of these modules, you must agree to separate terms and conditions applicable to
              these modules in order to use them.
            </li>
            <li>
              If we provide a coaches and affiliates module, we do not endorse or recommend any coaches or affiliates
              who advertise their services in the module. You must conduct your own enquiries on any coaches and
              affiliates, including identifying if they are suitable for your requirements. We do not accept any
              liability in connection with the products and services provided by any such coaches and affiliates.
            </li>
          </ol>
          <li>
            <h3>Liability</h3>
          </li>
          <ol>
            <li>
              We will not be liable to you under the law of tort (including negligence), contract or otherwise for any
              loss of profits or savings or for any indirect or consequential loss or damage, arising out of or in
              connection with these terms, including:
            </li>
            <ol type="a">
              <li>the performance or non-performance of any of our obligations under these terms; or</li>
              <li>your use of our platform or our services.</li>
            </ol>
            <li>
              Notwithstanding any other provision set out in these terms, our total aggregate liability for all claims
              relating to these terms is limited to the greater of $100 and the amount you have paid for our services in
              the 6 months preceding the event or circumstance giving rise to the claim.
            </li>
            <li>
              The limitations and exclusions of liability under this clause do not apply to limit our liability under or
              in connection with these terms for:
            </li>
            <ol type="a">
              <li>personal injury or death; or</li>
              <li>fraud or wilful misconduct.</li>
            </ol>
            <li>
              We are not liable to you for any failure to provide our platform or our service or to meet any of our
              obligations under these terms where that failure is caused by an event or circumstance outside of our
              reasonable control. This includes any act of God, strike, lockout, work stoppage or other labour
              hindrance, confiscation or expropriation, embargo, blockade, insurrections, public mains electrical supply
              failure, fire, floods, storm explosion, sabotage, revolution, riot, act of war whether declared or not,
              warlike operations, requirement or restriction of governmental authorities, order of the Court,
              earthquake, slide, epidemic, quarantine restriction, software failure or server failure or inability to
              access or connect to the internet for any reason.
            </li>
            <li>
              We are not liable to you (whether in contract, tort or otherwise) for your or any other user’s content,
              including in connection with any invoice that you or any other user create and send using the platform.
            </li>
            <li>
              Our liability for any claim relating to these terms will be reduced to the extent that you or any other
              entity or person associated with you contributed to the loss arising from the claim.
            </li>
          </ol>
          <li>
            <h3>WARRANTIES</h3>
          </li>
          <ol>
            <li>
              You warrant to us that you are using use our platform and services for your business purposes and that, to
              the maximum extent permitted by law, any statutory consumer guarantees or legislation that protect
              non-business consumers in any jurisdiction does not apply to the supply of our platform and services or
              these terms. This does not apply to you if you have only registered on the platform as a customer for open
              home viewings.
            </li>
            <li>
              We do not warrant or guarantee that your use of our platform and our service will be uninterrupted,
              error-free or will meet your purposes or expectations. To the maximum extent permitted by law, we exclude
              all such warranties or guarantees.
            </li>
            <li>
              We provide our market weighting tool for your assistance only and do not provide any warranties or
              guarantees (and accept no liability) in connection with your use of this tool.
            </li>
            <li>
              You control your privacy and other settings on the platform. We do not provide any warranties or
              guarantees (and accept no liability) in connection with these settings.
            </li>
            <li>
              You acknowledge and agree that our services rely on the accuracy, completeness and currency of information
              (such as property information) received from or posted by users and third parties, and that such
              information may not always be accurate, complete or up-to-date. We do not give any warranties or
              representations regarding the accuracy, completeness or currency of such information, or any information
              which incorporates such information or is based on or derived from such information.
            </li>
            <li>
              Other than as expressly stated in these terms and to the maximum extent permitted by law, we do not give
              any warranties or guarantees in relation to our platform and services or otherwise in connection with
              these terms.
            </li>
          </ol>
          <li>
            <h3>TERMINAtion</h3>
          </li>
          <ol>
            <li>
              You may terminate your subscription at any time provided that you have paid any outstanding sums owing to
              us under your plan or otherwise. We will not provide any refunds for any prepaid subscriptions.
            </li>
            <li>
              To terminate your subscription, please unsubscribe on our platform. We will endeavour to close your
              account promptly.
            </li>
            <li>
              We may at any time suspend or terminate your subscription if we consider that you are not eligible to use
              our services, if you have breached any of these terms or if you have infringed our or any other person’s
              proprietary or intellectual property rights. We may also terminate your subscription if you become
              bankrupt or make an arrangement with your creditors, or if your agency or business become insolvent, goes
              into liquidation or has a receiver or manager appointed to any of its assets. We will give you written
              notice that your account has been suspended or closed but we do not have to provide to you any reasons for
              the suspension or termination.
            </li>
            <li>
              If your subscription is terminated, then without prejudice to any other rights or remedies available:
            </li>
            <ol type="a">
              <li>
                we and you are immediately released from our respective obligations to each other under these terms
                except for any obligations that, by their nature, survive termination;
              </li>
              <li>we and you retain any claims that each may have against the other; and</li>
              <li>
                the licence granted to you under clause 7.2 to use our platform and services in accordance with these
                terms immediately terminates.
              </li>
            </ol>
          </ol>
          <li>
            <h3>take down policy</h3>
          </li>
          <ol>
            <li>
              If you reasonably consider that any person has infringed your intellectual property or other rights on our
              platform, you may send a written notice to us at{" "}
              <a href="mailto:infringements@utogi.com">infringements@utogi.com</a> with your contact details and
              sufficient information regarding the alleged infringement to enable us to assess whether an infringement
              has occurred.
            </li>
            <li>
              Once we receive a notice from you, we will determine what action to take in respect of the alleged
              infringement and will endeavour to respond to you promptly with details of the proposed action.
            </li>
          </ol>
          <li>
            <h3>DISPUTES</h3>
          </li>
          <ol>
            <li>
              If you have a complaint relating to our platform or services then you should contact us at{" "}
              <a href="mailto:info@utogi.com">info@utogi.com</a>. We aim to provide a written response to all complaints
              within 14 days. If you are dissatisfied with our response, we will consider reasonable requests to resolve
              the complaint through alternative dispute resolution procedures, including mediation or arbitration.
            </li>
          </ol>
          <li>
            <h3>Notices </h3>
          </li>
          <ol>
            <li>
              You may send a notice to us under or in connection with these terms by emailing us at [insert email
              address e.g. notices@[ ]].
            </li>
            <li>
              We may send a notice to you under or in connection with these terms by emailing you at the last email
              address you have provided to us.
            </li>
            <li>
              You or we will be deemed to have received a notice sent by email at the time that you or we sent it,
              unless we know or ought reasonably to know that the email was not delivered.
            </li>
          </ol>
          <li>
            <h3>General</h3>
          </li>
          <ol>
            <li>
              These terms (including any terms or policies explicitly incorporated by reference) constitute the entire
              agreement between you and us relating to your use of our platform or our services. These terms replace any
              previous related agreements and understandings between you and us.
            </li>
            <li>We may assign our rights and obligations under these terms at any time to any other person.</li>
            <li>
              We may subcontract all or any of our obligations under these terms to a third party. If we do this, we
              will remain liable to you for the performance of the subcontractor’s obligations.
            </li>
            <li>
              You must keep confidential any information you receive from us which you would expect to be confidential
              or commercially sensitive.
            </li>
            <li>
              No delay, neglect or forbearance in taking enforcement action in relation to any provision of these terms
              by a party will be a waiver, or in any way prejudice any right, of that party.
            </li>
            <li>
              If any part of these terms is held to be invalid, illegal or unenforceable, that part will be severed and
              the remainder of these terms will remain in full force and have full effect.
            </li>
            <li>
              These terms will be governed by the exclusive law of New Zealand and any legal action against us or you
              must be taken in a court in New Zealand.
            </li>
          </ol>
        </ol>
      </section>
    </div>
  );
};

export default termsAndConditions;
